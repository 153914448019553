
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  computed,
  ComputedRef,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { IStudent } from "@/store/modules/StudentModule";
import type { ElForm } from "element-plus";
import { IGroup } from "@/store/modules/GroupModule";
import store from "@/store";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "create-group-modal",
  components: {},
  setup() {
    const store = useStore();
    const CreateGroupModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const group: ComputedRef<IGroup> = computed(() => {
      return store.state.GroupModule.selectedGroup;
    });

    const formRef = ref<FormInstance>();
    const form = reactive({
      studentId: null,
      group: group.value,
      firstClassDate: new Date(),
    });

    const rules = ref({
      studentId: [
        {
          required: true,
          message: "Student is required",
          trigger: "change",
        },
      ],
      firstClassDate: [
        {
          type: "date",
          required: true,
          message: "First Class Date is required",
          trigger: "change",
        },
      ],
    });

    let students: ComputedRef<Array<IStudent>> = computed(() => {
      if (store.state.StudentModule.students) {
        return store.state.StudentModule.students.filter((student) => {
          return (
            student.cohort === null &&
            student.state === "ACTIVE" &&
            student.accountType === "CAR"
          );
        });
      } else return [];
    });

    onBeforeMount(() => {
      if (store.state.StudentModule.students.length === 0)
        store.dispatch(Actions.GET_STUDENTS);
    });

    const submit = (formEl: FormInstance | undefined) => {
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.ADD_STUDENT_TO_GROUP, form)
            .then(() => {
              Swal.fire({
                text: "Student has been added successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Close modal after pressing confirm button
                const closeButton: any = document.querySelector(
                  "#kt_add_student_to_group_modal_close"
                );
                if (closeButton) closeButton.click();
                loading.value = false;
                form.studentId = null;
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              loading.value = false;
              form.studentId = null;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loading.value = false;
          form.studentId = null;
          return false;
        }
      });
    };

    return {
      form,
      students,
      rules,
      submit,
      formRef,
      loading,
      CreateGroupModalRef,
    };
  },
});
