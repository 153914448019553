
import {
  defineComponent,
  ref,
  reactive,
  computed,
  ComputedRef,
  onBeforeMount,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ITeacher } from "@/store/modules/TeacherModule";
import type { ElForm } from "element-plus";
import { IScheduledClass } from "@/store/modules/IScheduleClass";
import moment from "moment";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "create-group-modal",
  components: {},
  setup(props, context) {
    const store = useStore();
    const loading = ref<boolean>(false);

    const rules = ref({
      teacherId: [
        {
          required: true,
          message: "Teacher is required",
          trigger: "change",
        },
      ],
    });

    let teachers: ComputedRef<Array<ITeacher>> = computed(() => {
      let teachers = store.state.TeacherModule.teachers;

      return teachers.filter((teacher) =>
        selectedScheduledClass.value?.session?.type === "THEORY"
          ? selectedScheduledClass.value?.theoryTeacher?.teacherId !==
            teacher.teacherId
          : selectedScheduledClass.value?.drivingTeacher?.teacherId !==
            teacher.teacherId
      );
    });

    onBeforeMount(() => {
      store.dispatch(Actions.GET_TEACHERS);
    });

    let selectedScheduledClass: ComputedRef<IScheduledClass> = computed(() => {
      return store.state.ScheduledClassesModule.selectedScheduledClass;
    });

    const formRef = ref<FormInstance>();
    const form = reactive({
      teacherId: null,
    });

    const submit = (formEl: FormInstance | undefined) => {
      if (!formEl) {
        return;
      }
      formEl.validate(async (valid) => {
        if (valid) {
          let dispatchAction = Actions.UPDATE_TEACHER_OF_SCHEDULED_CLASS;

          if (
            selectedScheduledClass.value.session.type === "THEORY" &&
            selectedScheduledClass.value.relatedClasses.length > 0
          ) {
            const swalResult = await Swal.fire({
              text: "Do you want to change the teacher for all theory classes happening at this time?",
              icon: "question",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes, all classes",
              denyButtonText: "No, only this one",
            });

            if (swalResult.isConfirmed) {
              dispatchAction = Actions.UPDATE_TEACHER_OF_SCHEDULED_CLASSES;
            } else if (swalResult.isDismissed) {
              return;
            }
          }

          loading.value = true;
          store
            .dispatch(dispatchAction, {
              ...form,
              scheduledClass: selectedScheduledClass.value,
            })
            .then(() => {
              // update the state selectedStudent to the newly created student
              Swal.fire({
                text: "Scheduled class has been updated successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Close modal after pressing confirm button
                const closeButton: any = document.querySelector(
                  "#kt_update_scheduled_class_modal_close"
                );
                if (closeButton) closeButton.click();
                loading.value = false;
              });
            })
            .catch((error) => {
              Swal.fire({
                text: "Conflict with teacher schedule",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loading.value = false;
          return false;
        }
      });
    };

    return {
      form,
      teachers,
      selectedScheduledClass,
      rules,
      submit,
      formRef,
      loading,
      moment,
    };
  },
});
