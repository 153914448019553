
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import UpdateGroupModal from "@/components/modals/forms/UpdateGroupModal.vue";
import AddStudentToGroupModal from "@/components/modals/forms/AddStudentToGroupModal.vue";
import UpdateScheduledClassModal from "@/components/modals/forms/UpdateScheduledClassModal.vue";

import store from "@/store";

import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { IGroup } from "@/store/modules/GroupModule";
import { IAttendance, IStudent } from "@/store/modules/StudentModule";
import { IScheduledClass, Session } from "@/store/modules/IScheduleClass";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import TemplateModule from "@/store/modules/TemplateModule";

export default defineComponent({
  name: "group-details",
  components: {
    FullCalendar,
    Datatable,
    UpdateGroupModal,
    AddStudentToGroupModal,
    UpdateScheduledClassModal,
  },
  setup(props, context) {
    const updateSelectedStudent = (student: IStudent) => {
      store.commit(Mutations.SET_SELECTED_STUDENT, student);
    };

    onBeforeMount(() => {
      store.dispatch(Actions.GET_GROUP);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Group Details", ["Apps", "Group"]);
    });

    const group: ComputedRef<IGroup> = computed(() => {
      return store.state.GroupModule.selectedGroup;
    });

    const iso8601DurationToMinutes = (duration: any) => {
      return moment.duration(duration).asMinutes();
    };

    const addMinutesToDate = (date, minutes) => {
      const endDate = new Date(date.getTime() + minutes * 60000);
      return endDate;
    };

    const scheduledClasses: ComputedRef<Array<any>> = computed(() => {
      if (group.value.students) {
        let scheduledClasses = group.value.students.reduce(
          (accumulator: Array<any>, student: IStudent) => {
            let classes: Array<any> = [];
            if (
              student.scheduledClasses &&
              student.scheduledClasses.length > 0
            ) {
              classes = student.scheduledClasses.map(
                (scheduledClass: IScheduledClass) => {
                  return {
                    id: scheduledClass.scheduledClassId,
                    groupId:
                      scheduledClass.session?.type === "THEORY"
                        ? scheduledClass.startDateTime +
                          scheduledClass.session.name
                        : scheduledClass.scheduledClassId,
                    title: `${student.fullName}: ${
                      scheduledClass.session?.name
                    } 
                      with ${
                        scheduledClass.session?.type === "THEORY"
                          ? scheduledClass.theoryTeacher.name
                          : scheduledClass.drivingTeacher.name
                      }`,
                    description: scheduledClass.session?.description,
                    start: scheduledClass.startDateTime,
                    end: addMinutesToDate(
                      new Date(scheduledClass.startDateTime),
                      iso8601DurationToMinutes(scheduledClass.session?.duration)
                    ),
                    className: "fc-event-primary",
                    overlap:
                      scheduledClass.session?.type === "THEORY" ? true : false,
                    extendedProps: { scheduledClass, student },
                    textColor: "black",
                    backgroundColor:
                      scheduledClass.session?.type === "THEORY"
                        ? scheduledClass.theoryTeacher.teacherId ===
                          group.value.theoryTeacher?.teacherId
                          ? "rgb(230, 199, 255)"
                          : "rgb(200, 133, 255)"
                        : scheduledClass.drivingTeacher.teacherId ===
                          group.value.drivingTeacher?.teacherId
                        ? "rgb(255, 221, 199)"
                        : "rgb(255, 157, 92)",
                  };
                }
              );
            }
            return accumulator.concat(classes);
          },
          []
        );

        return scheduledClasses;
      } else return [];
    });

    const todayDate = moment().startOf("day");
    const TODAY = todayDate.format("YYYY-MM-DD");

    const getUpdatedScheduledClasses = (
      event: EventApi,
      relatedEvents: EventApi[]
    ) => {
      const events = [event].concat(relatedEvents);
      const classes = events.map((e) => {
        return {
          ...e?._def?.extendedProps.scheduledClass,
          startDateTime: event?._instance?.range.start.toISOString(),
          endDateTime: event?._instance?.range.end.toISOString(),
          session: {
            ...e?._def?.extendedProps.scheduledClass.session,
            duration: moment
              .duration(
                moment(event?._instance?.range.end).diff(
                  moment(event?._instance?.range.start)
                )
              )
              .toISOString(),
          },
        };
      });

      return classes;
    };

    const calendarOptions: ComputedRef<CalendarOptions> = computed(() => {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        height: 900,
        contentHeight: 880,
        aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

        nowIndicator: true,
        now: TODAY + "T09:25:00", // just for demo

        views: {
          dayGridMonth: { buttonText: "month" },
          timeGridWeek: { buttonText: "week" },
          timeGridDay: { buttonText: "day" },
        },

        initialView: "dayGridMonth",
        initialDate: TODAY,
        dayMaxEvents: true, // allow "more" link when too many events
        navLinks: true,
        events: scheduledClasses.value,

        editable: true,
        eventStartEditable: true,
        eventResizableFromStart: true,
        eventDrop: function (info) {
          if (!confirm("Are you sure about this change?")) {
            info.revert();
          } else {
            const updatedClasses = getUpdatedScheduledClasses(
              info.event,
              info.relatedEvents
            );

            const allow = isClassWithinAllowedRange(updatedClasses[0]);
            if (allow) {
              store
                .dispatch(Actions.UPDATE_SCHEDULED_CLASSES, updatedClasses)
                .catch((err) => {
                  info.revert();
                  alert("Conflict with teacher schedule!");
                });
            } else {
              info.revert();
              alert("Not within teacher schedule!");
            }
          }
        },
        eventResize: function (info) {
          if (!confirm("Are you sure about this change?")) {
            info.revert();
          } else {
            const updatedClasses = getUpdatedScheduledClasses(
              info.event,
              info.relatedEvents
            );

            const allow = isClassWithinAllowedRange(updatedClasses[0]);
            if (allow) {
              store
                .dispatch(Actions.UPDATE_SCHEDULED_CLASSES, updatedClasses)
                .catch((err) => {
                  info.revert();
                  alert("Conflict with teacher schedule!");
                });
            } else {
              info.revert();
              alert("Not within teacher schedule!");
            }
          }
        },
        eventClick: function (info) {
          const relatedClasses = scheduledClasses.value.filter((c) => {
            return (
              info.event._def.extendedProps.scheduledClass.startDateTime ===
                c.extendedProps.scheduledClass.startDateTime &&
              info.event._def.extendedProps.scheduledClass.session.type ===
                "THEORY" &&
              info.event._def.extendedProps.scheduledClass.scheduledClassId !==
                c.extendedProps.scheduledClass.scheduledClassId
            );
          });
          store.commit(Mutations.SET_SELECTED_SCHEDULED_CLASS, {
            ...info.event._def.extendedProps.scheduledClass,
            student: info.event._def.extendedProps.student,
            relatedClasses: relatedClasses,
          });
          const btn = document.getElementById(
            "kt_update_scheduled_class_modal_btn"
          );
          if (btn) btn.click();
        },
      };
    });

    const isClassWithinAllowedRange = ({
      startDateTime,
      endDateTime,
      theoryTeacher,
      drivingTeacher,
      session,
    }) => {
      let groupTeacher =
        session.type === "THEORY"
          ? group.value.theoryTeacher
          : group.value.drivingTeacher;
      let teacher = session.type === "THEORY" ? theoryTeacher : drivingTeacher;
      let startDateTimeDate = new Date(startDateTime);
      let endDateTimeDate = new Date(endDateTime);
      var isWeekend =
        startDateTimeDate.getDay() === 6 || startDateTimeDate.getDay() === 0;

      var startHours = 0;
      if (
        groupTeacher?.startTimeRangeWeekday &&
        groupTeacher?.startTimeRangeWeekend
      )
        startHours = isWeekend
          ? parseInt(teacher.startTimeRangeWeekend.substring(0, 2))
          : parseInt(teacher.startTimeRangeWeekday.substring(0, 2));

      var endHours = 0;
      if (
        groupTeacher?.endTimeRangeWeekday &&
        groupTeacher?.endTimeRangeWeekend
      )
        endHours = isWeekend
          ? parseInt(teacher.endTimeRangeWeekend.substring(0, 2))
          : parseInt(teacher.endTimeRangeWeekday.substring(0, 2));

      var startMinutes = 0;
      if (
        groupTeacher?.startTimeRangeWeekday &&
        groupTeacher?.startTimeRangeWeekend
      )
        startMinutes = isWeekend
          ? parseInt(teacher.startTimeRangeWeekend.substring(3, 5))
          : parseInt(teacher.startTimeRangeWeekday.substring(3, 5));

      var endMinutes = 0;
      if (
        groupTeacher?.endTimeRangeWeekday &&
        groupTeacher?.endTimeRangeWeekend
      )
        endMinutes = isWeekend
          ? parseInt(teacher.endTimeRangeWeekend.substring(3, 5))
          : parseInt(teacher.endTimeRangeWeekday.substring(3, 5));

      if (
        startDateTimeDate.getUTCHours() === startHours &&
        startDateTimeDate.getUTCMinutes() < startMinutes
      ) {
        return false;
      } else if (
        endDateTimeDate.getUTCHours() === endHours &&
        endDateTimeDate.getUTCMinutes() > endMinutes
      ) {
        return false;
      } else if (
        startDateTimeDate.getUTCHours() < startHours ||
        startDateTimeDate.getUTCHours() > endHours
      ) {
        return false;
      } else if (
        endDateTimeDate.getUTCHours() > endHours ||
        endDateTimeDate.getUTCHours() < startHours
      ) {
        return false;
      }

      return true;
    };

    const tableHeader = ref([
      {
        name: "Id",
        key: "studentId",
        sortable: true,
      },
      {
        name: "Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Type",
        key: "accountType",
        sortable: true,
      },
      {
        name: "Date of Birth",
        key: "dob",
      },
      {
        name: "State",
        key: "state",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
      },
    ]);

    const checkIfStudendedAttendedSession = (
      attendances: IAttendance[],
      session: Session
    ): boolean => {
      return (
        attendances.filter(
          (attendance) =>
            attendance.session?.name === session.name && attendance.attended
        ).length > 0
      );
    };

    const studentAttendances: ComputedRef<Array<any>> = computed(() => {
      if (group.value.template) {
        const sessions = TemplateModule.templateToSessions(
          group.value.template
        );
        if (group.value.students) {
          const stuAttendances = group.value.students.map((student) => {
            const studentSessions = sessions.map((session) => {
              if (student.attendances)
                return {
                  ...session,
                  attended: checkIfStudendedAttendedSession(
                    student.attendances,
                    session
                  ),
                };
              else return { ...session };
            });

            return { ...student, studentSessions };
          });
          const sortedStudents = stuAttendances.sort((a, b) =>
            a.fullName && b.fullName && a.fullName > b.fullName ? 1 : -1
          );
          return sortedStudents;
        }
        return sessions;
      }
      return [];
    });

    const toggleAttendence = (studentId: number, sessionName: string) => {
      store.dispatch(Actions.TOGGLE_STUDENT_ATTENDANCE, {
        studentId,
        sessionName,
      });
    };

    const removeStudentFromGroup = (studentId: number, cohortId: number) => {
      store.dispatch(Actions.REMOVE_STUDENT_FROM_GROUP, {
        studentId,
        cohortId,
      });
    };

    return {
      group,
      scheduledClasses,
      studentAttendances,
      toggleAttendence,
      removeStudentFromGroup,
      calendarOptions,
      currentEvents: [] as EventApi[],
      tableHeader,
      updateSelectedStudent,
    };
  },
});
