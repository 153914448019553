
import { computed, ComputedRef, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IGroup } from "@/store/modules/GroupModule";

export default defineComponent({
  name: "update-group-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateGroupModalRef = ref<null | HTMLElement>(null);

    const group: ComputedRef<IGroup> = computed(() => {
      return store.state.GroupModule.selectedGroup;
    });

    let groupToEdit: ComputedRef<IGroup> = computed(() => {
      return {
        cohortId: group.value.cohortId,
        name: group.value.name,
        state: group.value.state,
      };
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("name"),
      state: Yup.string().required().label("state"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      store.dispatch(Actions.MODIFY_GROUP, groupToEdit.value);
      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(updateGroupModalRef.value);
        });
      }, 200);
    };

    return {
      group,
      groupToEdit,
      validationSchema,
      submit,
      submitButtonRef,
      updateGroupModalRef,
    };
  },
});
